@import '../../../../scss/theme-bootstrap';

.signin-widget {
  border-top: solid 1px $color-belgian-linen;
  clear: both;
  position: static;
  width: 100%;
  @include breakpoint($medium-up) {
    width: 350px;
  }
  .left {
    text-align: #{$ldirection};
  }
  .right {
    text-align: #{$rdirection};
  }
  .center {
    text-align: center;
  }
  &__header {
    padding: 10px 20px;
    text-align: $ldirection;
    overflow: auto;
    @include breakpoint($medium-up) {
      padding: 20px;
    }
  }
  &__header-profile-container {
    float: $ldirection;
    width: 25%;
  }
  &__header-profile-image {
    padding-#{$rdirection}: 10px;
    width: 100%;
  }
  &__header-content {
    float: $rdirection;
    width: 75%;
  }
  &__header-wishes {
    font-weight: 700;
    text-transform: capitalize;
  }
  &__container {
    padding: 0;
    text-align: center;
    width: inherit;
  }
  &__account-settings-link {
    color: inherit;
    font-size: 14px;
    text-decoration: underline;
    &:hover {
      color: inherit;
    }
  }
  &__tout--close {
    #{$rdirection}: 10px;
    background: none;
    border: 0;
    color: inherit;
    cursor: pointer;
    min-width: auto;
    position: absolute;
    top: 10px;
    @include breakpoint($medium-up) {
      height: 15px;
      width: 15px;
    }
    &:hover {
      background: none;
      color: inherit;
    }
    .icon--close {
      height: 15px;
      width: 15px;
      fill: $color-white;
    }
  }
  &__content {
    border-top: solid 1px $color-belgian-linen;
  }
}
